import { Card } from "primereact/card";
import Search from "../common/Search";
import Filters from "./Filters";
import ProfileSearchResult from "./ProfileSearchResult";
import { Game } from "../../models/Game";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios"
import { AppConstants } from "../../constants/AppConstants";
import { ProfileHeader } from "../../models/Profile";


const ProfileSearch = () => {
    const [games, setGames] = useState<Game[]>([] as any)
    const [gamesCount, setGameCount] = useState<number>(0)

    const [searchText, setSearchText] = useState<string>("")

    function createProfiles( profileItems: any[]){
        const profiles:ProfileHeader[] = []

        profileItems.forEach( profileItem =>{
            profiles.push({
                id: profileItem.profileId,
                name: profileItem.profileName,
                author: profileItem.author,
                downloadCount: profileItem.downloadCount,
                platforms: profileItem.platforms,
                tags: profileItem.tags,
                rating: profileItem.rating
            })
        })
        return profiles;
    }

    function prepareSearchResult(items : any[]){
        const azureSite = "https://saglydrhubs.blob.core.windows.net/game-images/";

        const games: Game[] = []
        items.forEach( item =>{
            games.push( {
                id: item.game.gameId,
                name: item.game.gameName,
                imageUrl: azureSite + item.game.gameId + ".jpg",
                tags: item.game.tags,
                platforms: item.game.platforms,
                submittedOn: new Date(),
                submitterBy: "anonymous",
                profiles: createProfiles(item.glydrProfiles),
            })
        })
        return games;
    }

    const onSearchTextChange = (searchText:string) =>{
        setSearchText(searchText)

    }

    useEffect( ()=>{
        const url = `${AppConstants.SITE_URL}/ProfileSearch/Search`
        const postData = {
            "text": searchText,
            "tags": [],
            "platforms": [],
            "emulationModes": [],
            "connectionTypes": []
        }
        axios.post(url, postData).then( response => {
            console.log(response.data)
            const games: Game[] = prepareSearchResult( response.data);
            setGames(games)
            setGameCount(games.length)
            console.log(games)
        })
    }, [searchText]);

    const location = useLocation()
    return (
        <div className="grid">
            <Card className="col-3">
                <div className="flex flex-column ">
                    <Filters platform={location.state?.platform} />
                </div>

            </Card>
            <div className="col-9 pl-3">
                <Search searchQuery={location?.state?.searchQuery} onSearchTextChange={onSearchTextChange}/>
                <ProfileSearchResult games={games} />
            </div>
        </div>
    );
}

export default ProfileSearch;

