import { Game } from "../../models/Game";
import GameItem from "./GameItem";

const GameItemList = (props: any) => {
    const games = props.games as Game[];

    const gameItems = games.map( game => <GameItem game={game} key={game.id}/>)

    return(
        <>{gameItems}</>
    )
}
 
export default GameItemList;