
import { Checkbox } from 'primereact/checkbox';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { AppConstants } from '../../constants/AppConstants';

export default function Filters(props: any) {

    const [platforms, setplatforms] = useState<string[]>([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);

    const [tags, setTags] = useState(['']);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const [creators, setCreators] = useState(['']);
    const [selectedCreators, setSelectedCreators] = useState<string[]>([]);

    const [emulations, setEmulations] = useState(['']);
    const [selectedEmulationMode, setSelectedEmulationMode] = useState(['']);

    const [connections, setConnections] = useState(['']);
    const [selectedConnections, setSelectedConnections] = useState(['']);


    useEffect(()=>{
        let url = AppConstants.SITE_URL + "/ProfileSearch/Platforms";
        axios.get( url, { headers: {"Access-Control-Allow-Origin" :"*", credentials: true } } ).then (response =>{
            setplatforms(response.data || [])
        })
        url = AppConstants.SITE_URL + "/ProfileSearch/Tags";
        axios.get( url, { headers: {"Access-Control-Allow-Origin" :"*", credentials: true } } ).then (response =>{
            setTags(response.data || [])
        })
        url = AppConstants.SITE_URL + "/ProfileSearch/Creators";
        axios.get( url, { headers: {"Access-Control-Allow-Origin" :"*", credentials: true } } ).then (response =>{
            setCreators(response.data || [])
        })
        url = AppConstants.SITE_URL + "/ProfileSearch/EmulationModes";
        axios.get( url, { headers: {"Access-Control-Allow-Origin" :"*", credentials: true } } ).then (response =>{
            const modes = response.data as any[]
            setEmulations(modes.map( item=> item.value))
        })

        url = AppConstants.SITE_URL + "/ProfileSearch/ConnectionTypes";
        axios.get( url, { headers: {"Access-Control-Allow-Origin" :"*", credentials: true } } ).then (response =>{
            const modes = response.data as any[]
            setConnections(modes.map( item=> item.value))
        })
    }, [])

    const onPlatformsChange = (e: any) => {
        let _platforms = [...selectedPlatforms];

        if (e.checked && !selectedPlatforms.includes(e.value))
            _platforms.push(e.value);
        else
            _platforms.splice(_platforms.indexOf(e.value), 1);
        setSelectedPlatforms(_platforms);
    }

    const onTagsChange = (e: any) => {
        let _tags = [...selectedTags];

        if (e.checked)
            _tags.push(e.value);
        else
            _tags.splice(_tags.indexOf(e.value), 1);
        setSelectedTags(_tags);
    }

    const onCreatorChange = (e: any) => {
        let _creators = [...selectedCreators];

        if (e.checked)
            _creators.push(e.value);
        else
            _creators.splice(_creators.indexOf(e.value), 1);
        setSelectedCreators(_creators);
    }

    const onEmulationChange = (e: any) => {
        let _emulations = [...selectedEmulationMode];

        if (e.checked)
            _emulations.push(e.value);
        else
            _emulations.splice(_emulations.indexOf(e.value), 1);
        setSelectedEmulationMode(_emulations);
    }

    const onConnectionChange = (e: any) => {
        let _connections = [...selectedConnections];

        if (e.checked)
            _connections.push(e.value);
        else
            _connections.splice(_connections.indexOf(e.value), 1);
        setSelectedConnections(_connections);
    }

    const platformList = platforms.map( platform =>{
        return(
            <div className="flex align-items-center mb-2" key={platform}>
                <Checkbox inputId={platform} name={platform} value={platform} onChange={onPlatformsChange} checked={selectedPlatforms.includes(platform)} />
                <label htmlFor={platform} className="ml-2 text-xs">{platform}</label>
            </div>
        )
    })

    const tagList = tags.slice(0, 8).map( tag =>{
        return(
            <div className="flex align-items-center mb-2" key={tag}>
                <Checkbox inputId={tag} name={tag} value={tag} onChange={onTagsChange} checked={selectedTags.includes(tag)} />
                <label className="ml-2 text-xs">{tag}</label>
            </div>
        )
    })

    const creatorList = creators.slice(0, 8).map( creator =>{
        return(
            <div className="flex align-items-center mb-2" key={creator}>
                    <Checkbox inputId={creator} name={creator} value={creator} onChange={onCreatorChange} checked={selectedCreators.includes(creator)} />
                    <label className="ml-2 text-xs">{creator}</label>
            </div>
        )
    })

    const emulationList = emulations.map( emulation =>{
        return(
            <div className="flex align-items-center mb-2" key={emulation}>
                    <Checkbox inputId={emulation} name={emulation} value={emulation} onChange={onEmulationChange} checked={selectedEmulationMode.includes(emulation)} />
                    <label className="ml-2 text-xs">{emulation}</label>
            </div>
        )
    })

    const connectionList = connections.map( connection =>{
        return(
            <div className="flex align-items-center mb-2" key={connection}>
                    <Checkbox inputId={connection} name={connection} value={connection} onChange={onConnectionChange} checked={selectedConnections.includes(connection)} />
                    <label className="ml-2 text-xs">{connection}</label>
            </div>
        )
    })
    return (
        <div>
            <h4>Platforms</h4>
            <div className="card flex flex-wrap justify-content-center gap- flex-column">
                {platformList}
            </div>

            <h4 className='mt-6'>Tags</h4>
            <div className="card flex flex-wrap justify-content-center gap- flex-column">
                {tagList}                
            </div>

            <h4 className='mt-6'>Popular Creators</h4>
            <div className="card flex flex-wrap justify-content-center gap- flex-column">
               {creatorList}
            </div>

            <h4 className='mt-6'>Emulation Mode</h4>
            <div className="card flex flex-wrap justify-content-center gap- flex-column">
                {emulationList}
            </div>

            <h4 className='mt-6'>Connection Type</h4>
            <div className="card flex flex-wrap justify-content-center gap- flex-column">
                {connectionList}
            </div>
        </div>
    )
}

