import { Game } from "../../models/Game";
import GameItemList from "./GameItemList";
import styles from "./ProfileSearchResult.module.css"

const ProfileSearchResult = ( props:any) => {
    return ( 
        <>
            <div className={styles.resultsLabel}>{props.games.length} results based of search criteria</div>
            <GameItemList games={props.games}/>
        </>
    );
}
 
export default ProfileSearchResult;