import { Game } from "../../models/Game";
import { useEffect, useState } from "react";
import { searchResults as RG } from "../../mocks/SearchResultMock";
import { searchResults as PG } from "../../mocks/SearchResultMock";

import { CategoryList } from "./CategoryList";
import { AppConstants } from "../../constants/AppConstants";
import axios from "axios";

const CategoryListResults = () => {
    const [recommendedGames, setRecommendedGames] = useState<Game[]>([] as any)
    const [popularGames, setPopularGames] = useState<Game[]>([] as any)
    const [groupings, setGroupings] = useState<Game[]>([] as any)
    const [gamesCount, setGameCount] = useState<number>(0)

    useEffect(() => {
        let url = AppConstants.SITE_URL + "/Homepage/HomepageGroupings";
        axios.get(url, { headers: { "Access-Control-Allow-Origin": "*", credentials: true } }).then(response => {
            setGroupings(response.data || [])
        })
    }, [])

    return (
        <div>
            <div className=" flex flex-column mb-3">

                {groupings.map((group: any) => <CategoryList key={group.groupingName} games={group.games} title={group.groupingName} />)}
            </div>
            {/* <div className=" flex flex-column">
                <CategoryList games={popularGames} title = "Most Popular Games"/>
            </div>
            <div className=" flex flex-column">
                <CategoryList games={popularGames} title = "High Ratings"/>
            </div>
            <div className=" flex flex-column">
                <CategoryList games={popularGames} title = "Profile Review Ratings"/>
            </div> */}
        </div>
    );
}

export default CategoryListResults;

