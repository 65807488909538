import { classNames } from "primereact/utils";
import { Game } from "../../models/Game";
import Tags from "../common/Tags";
import styles from "./GameItem.module.css"
import SupportedPlatforms from "./SupportedPlatforms";
import { SplitButton } from "primereact/splitbutton";
import { Button } from "primereact/button";
import { useState } from "react";
import ProfileItemList from "./ProfileItemList";
import { useNavigate } from "react-router";

const GameItem = (props: any) => {
    const [profileListDisplay, setProfileListDisplay] = useState<string>("none")
    const game = props.game as Game;
    const navigate = useNavigate();

    const btnLabel = `${game.profiles.length} profiles available`

    function toggleProfiles(){
        if ( profileListDisplay == "none" ) setProfileListDisplay("block");
        else if ( profileListDisplay == "block" ) setProfileListDisplay("none");
        else setProfileListDisplay( "none")
    }

    const onGameClick = ((e:  any) => {
        navigate("/profile-detail", { state: { profileId: '1'} });
    })

    return (
        <div className={styles.gameItem} >
            <div className="flex flex-row gap-5 justify-content-between">
                <div className="flex flex-row gap-2">
                    <img src={game.imageUrl} style={{width: '200px', height: '120px', objectFit: 'cover'}}/>
                    <div className="flex flex-column gap-1 justify-content-start p-2">
                        <span className={styles.title}>{game.name}</span>
                        <div className="flex flex-column gap-2">
                            <div>
                                <SupportedPlatforms platforms={game.platforms}/>
                            </div>
                            <div>
                                <Tags tags={game.tags}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex align-items-center justify-content-center p-3">
                    <Button outlined size="small" text  label={btnLabel} icon="pi pi-sort-down" iconPos="right" onClick={toggleProfiles}/>
                </div>
            </div>
            <div style={{display: profileListDisplay}}>
                <div className="slideDown">
                    <ProfileItemList profiles={game.profiles}/>
                </div>
                
            </div>
        </div>
    );
}
 
export default GameItem;