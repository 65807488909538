import { Link } from "react-router-dom";
import styles from "./HeaderDetail.module.css"
import { SplitButton } from "primereact/splitbutton";
import { Toast } from 'primereact/toast';
import { useRef, useState } from "react";
import { Button } from "primereact/button";
import Search from "../../common/Search";

const HeaderDetail = () => {
    const [userName, setUserName] = useState("idutta2007")
    const toast = useRef<Toast>(null);

    const items = [
        {
            label: 'Profile',
            icon: 'pi pi-bars',
            command: () => {
                toast.current && toast.current.show({ severity: 'success', summary: 'Success', detail: 'Going to user profile' });
            }
        },
        {
            label: 'Sign out',
            icon: 'pi pi-sign-out',
            command: () => {
                toast.current && toast.current.show({ severity: 'success', summary: 'Success', detail: 'Signing user out' });
            }
        },
    ]

    const showUserProfile = () => {
    }

    return (
        <>
            <div className="flex flex-row gap-3 align-items-center">
                <Link to={`/`} >
                    <Button className="p-2" outlined label="Catalog" />
                </Link>

                <Button className="p-0" label="Community" text />
                <Button className="p-0" label="Contact us" text />
                <Button className="p-0" label="About" text />
                <Link to={`/profile-search`} >
                    <Button className="p-0" label="Find Profile" text />
                </Link>
                <Search />

                <div className={styles.rightPanel}>
                    <SplitButton size="small" outlined label={userName} icon="pi pi-user" onClick={showUserProfile} model={items} />
                </div>
            </div>
            <Toast ref={toast}></Toast>
        </>
    );
}

export default HeaderDetail;