import { useEffect, useState } from "react";
import { AutoComplete } from 'primereact/autocomplete';
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { AppConstants } from "../../constants/AppConstants";
import { Game } from "../../models/Game";
        
export default function Search(props: any) {
    const location = useLocation()
    const navigate = useNavigate();
    const [value, setValue] = useState(props.searchQuery);
    const [gameNames, setGameNames] = useState<string[]>([]);
    const [filteredNames, setFilteredNames] = useState<string[]>([]);
    useEffect(()=>{
        let url = AppConstants.SITE_URL + "/Games";
        axios.get( url, { headers: {"Access-Control-Allow-Origin" :"*", credentials: true } } ).then (response =>{
            if(response){
                const games : Game[] = response.data as Game[]
                setGameNames(games.map(game => game.name)) ;             
            }
        })       
    }, [])   

    const search = (event: { query: any; }) => {
         let query = event.query;
         let _filteredItems = [];
 
         for(let i = 0; i < gameNames.length; i++) {
             let item = gameNames[i];
             if (item?.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                 _filteredItems.push(item);
             }
         } 
         setFilteredNames(_filteredItems);
    }

    const onSelect = (e: any) => {
        if(location.pathname === '/') {
            navigate("/profile-search", { state: { searchQuery: e.value} });
        }
        props.onSearchTextChange && props.onSearchTextChange(e.value)      
    }
    return (
        <div className="p-fluid">
            <AutoComplete onSelect={onSelect} value={value}
                className=" " 
                suggestions={filteredNames} 
                completeMethod={search} 
                placeholder="Search Games, Tags..." 
                onChange={(e) => setValue(e.value)} />
        </div>            
    )
}