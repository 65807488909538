import { ProfileHeader } from "../../models/Profile";
import ProfileItem from "./ProfileItem";
import styles from "./ProfileItemList.module.css"

const ProfileItemList = (props: any) => {
    const profiles = props.profiles as ProfileHeader [];

    const list = profiles.map( profile => <ProfileItem profile={profile} key={profile.id}/>)

    return (
        <div className={styles.profileListContainer}>
            <div className="flex justify-content-between " style={{padding: '0.5rem', backgroundColor: 'var(--surface-100)', color: 'var(--text-color)'}}>
                <div >Profiles</div>
                <i className="pi pi-filter" style={{ fontSize: '0.8rem' }}></i>
            </div>
            
            <div className={styles.profileList}>
                {list}
            </div>
        </div>
    );
}
 
export default ProfileItemList;