import React from 'react';
import logo from './logo.svg';
import './App.css';
import '../node_modules/primeflex/primeflex.css'
import '../node_modules/primeicons/primeicons.css';
import '../node_modules/primereact/resources/themes/lara-dark-blue/theme.css'

import Header from './components/layout/header/Header';
import WorkArea from './components/layout/WorkArea';
import Footer from './components/layout/footer/Footer';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { ProgressSpinner } from 'primereact/progressspinner';

function App() {
  return (
    <div className='grid'>
      {/* <div className='col-10 col-offset-1'> */}
      <div className='col-12'>
        <PrimeReactProvider>
          <Header />
          <WorkArea />
          <Footer />
        </PrimeReactProvider>
      </div>

    </div>
  );
}

export default App;
