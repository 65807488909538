import { Rating } from "primereact/rating";
import { Profile } from "../../models/Profile";
import styles from "./ProfileItem.module.css"
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

const ProfileItem = (props: any) => {
    const profile = props.profile as Profile
    return (
        <div className="grid profileItem">
            <div className="col-6">
                <Link className="mb-3" to={`/profile-detail?profile_id=${profile.id}`} ><div >{profile.name}</div></Link>
                <small className="text-sm text-500">Downloads:<b>{profile.downloadCount}</b></small>
            </div>
            
            <div className="col-2">
                <Rating value={profile.rating} readOnly cancel={false} />
            </div>
            <div className="col-2">
            </div>
            <div className="col-2">
                <Button severity="success" label="Activate" size="small" icon="pi pi-download" />
            </div>
        </div>

    );
}

export default ProfileItem;