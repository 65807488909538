
import { Route, Routes } from 'react-router-dom';
import Home from '../home/Home';
import ProfileDetail from '../profile-detail/ProfileDetail';
import styles from "./WorkArea.module.css"
import { useState } from 'react';
import ProfileSearch from '../profile-search/ProfileSearch';
import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const WorkArea = () => {


    return (
      <>
      <div className='mb-0'>
        <img src="/images/glydr-couch.gif" width={'100%'} height={'300px'} style={{objectFit: 'cover', objectPosition: 'bottom'}}  alt="" />
      </div> 
      <div className={styles.workarea}>
        <React.Suspense fallback={<ProgressSpinner/>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile-search" element={<ProfileSearch/>} />
            <Route path="/profile-detail" element={<ProfileDetail/>} />
          </Routes> 
        </React.Suspense>        
      </div>
      </>
     );
}
 
export default WorkArea;