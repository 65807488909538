import { Carousel, CarouselResponsiveOption } from "primereact/carousel";
import { Game } from "../../models/Game";
import { Tag } from "primereact/tag";

const categoryItemTemplate = (game: Game) => {
    return (
        <div className="border-1 surface-border border-round m-2 text-center ">
            <img src={`https://saglydrhubs.blob.core.windows.net/game-images/${game.gameId}.jpg`} alt={game.gameName} width="300" height="290" className="w-12 shadow-2 " style={{ objectFit: 'cover'}} />
            {/* <img src="/images/Game3.png" alt={game.name} width="300" height="290" className="w-12 shadow-2 " style={{ objectFit: 'cover' }} /> */}
            <div style={styles.categoryItemTemplate}>
                <div>
                    {/* <h4 className="m-0">{game.gameName}</h4> */}
                    <small className="text-xs">{game.profileCount} Profiles</small>
                </div>
                <div>
                    <Tag severity="success" value="New" className="border-noround"></Tag>
                </div>

            </div>
        </div>
    );
}

export const CategoryList = (props: any) => {
    const games = props.games as Game[];
    const responsiveOptions: CarouselResponsiveOption[] = [
        {
            breakpoint: '1400px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    return (
        <div>
            <div >
                <h2 className="ml-6 mb-1" >{props.title}</h2>
                <Carousel value={games} numScroll={1} numVisible={4} showNavigators={true} showIndicators={false} responsiveOptions={responsiveOptions}
                    autoplayInterval={5000}
                    itemTemplate={categoryItemTemplate} />
            </div>
        </div>
    )
}

const styles = {
    categoryItemTemplate: {
        backgroundImage: 'url(images/background_spotlight.jpg)',
        marginTop: '-5px',
        minHeight: '50px',
        backgroundSize: 'cover',
        padding: '10px',
        'text-align': 'start',
        display: 'flex',
        justifyContent: 'space-between',
    },
    gameTitle: {
        width: '250px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}