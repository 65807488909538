import { Link } from "react-router-dom";
import styles from "./Footer.module.css"

const Footer = () => {
    return ( 
        <div className={styles.footer}>
            <div className="flex flex-row gap-3">
                <div>
                    &copy;Copyright Glydr&reg;, 2024
                </div>
                <Link className={styles.footerLink} to="/">About</Link>
                <Link className={styles.footerLink} to="/">Contact Us</Link>
            </div>
        </div>
     );
}
 
export default Footer;