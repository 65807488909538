import styles from "./Header.module.css"
import HeaderDetail from "./HeaderDetail";
import HeaderLogo from "./HeaderLogo";

const Header = () => {
    return ( 
        <div className={styles.header}>
            <div className="flex flex-row align-items-center gap-6">
                <a href="/"><HeaderLogo/></a>
                <HeaderDetail/>
            </div>
        </div>
     );
}
export default Header;