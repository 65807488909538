import { Card } from "primereact/card";
import Filters from "./Filters";
import CategoryList from "../category/CategoryListResults";

const Home = () => {
    return (
            <div className="col-12 pt-0 pb-0">
                {/* <Search /> */}
                <div className="flex flex-column full-width mt-3">
                    <CategoryList />
                </div>
            </div>
    );
}

export default Home;
