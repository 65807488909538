import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Rating } from "primereact/rating";
import { Tag } from "primereact/tag";
import { Fieldset } from 'primereact/fieldset';
import { Avatar } from "primereact/avatar";
import { Chip } from "primereact/chip";
import { TabMenu } from "primereact/tabmenu";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { AppConstants } from "../../constants/AppConstants";
import axios from "axios";

const ProfileDetail = () => {

    const location = useLocation()
    let profileId;
    const [profile, setProfile] = useState<any>()
    const [game, setGame] = useState<any>()

    useEffect(() => {
        profileId = location.search?.split('=')[1] || null

        let url = AppConstants.SITE_URL + `/GlydrProfileMetadata/${profileId}`;
        axios.get(url, { headers: { "Access-Control-Allow-Origin": "*", credentials: true } }).then(response => {
            setProfile(response.data || [])
            let url1 = AppConstants.SITE_URL + `/Games/${response.data.gameIds[0]}`;
            axios.get(url1, { headers: { "Access-Control-Allow-Origin": "*", credentials: true } }).then(response => {
                setGame(response.data || [])
            })
        })


    }, [])

    const [selectedTab, setSelectedTab] = useState("Sensitivity")

    const settings = [
        {
            label: 'Sensitivity', icon: 'pi pi-align-center', command: () => {
                setSelectedTab("Sensitivity")
            }
        },
        {
            label: 'Controls', icon: 'pi pi-cog', command: () => {
                setSelectedTab("Controls")
            }
        }
    ];

    const legendTemplate = (
        <div className="flex align-items-center gap-2 px-0">
            <Avatar image="images/avatar.png" shape="circle" />
            <span className="font-bold">Amy Elsner</span>
        </div>
    );
    return (
        <div className="grid">
            <div className='col-10 col-offset-1'>
                <div className="flex flex-column">
                    <div className="flex flex-row justify-content-between">
                        <div className="flex flex-column">
                            <h2 className="text-primary text-900 mb-1 mt-0">{profile?.name || 'N/A'}</h2>
                            <small>Created by : <b>{profile?.author || 'N/A'}</b></small>
                            <small className="mb-3"><b>{profile?.downloadCount || 0} </b>downloads</small>
                        </div>

                        <div>
                            <Rating value={profile?.rating} readOnly cancel={false} />
                            <Button className="mt-2" label="Activate" size="small" severity="success" icon="pi pi-download" raised />
                        </div>

                    </div>
                </div>
                <div className="grid mt-3">
                    <div className="col-9 p-0">
                        {/* <img style={styles.img} src="images/glydr-couch.gif" alt="" /> */}
                        <TabMenu model={settings} />
                        <img style={styles.img} src={selectedTab == 'Sensitivity' ? 'images/sensitivity.png' : 'images/controls.png'} alt="" />
                    </div>
                    <Card style={styles.profileDetailCard} className="col-3 p-0 m-0" >
                        <p>Game Title: <b className="text-primary">{game?.name}</b></p>
                        <p>Platform:  <i className="pi pi-desktop text-primary"></i></p>
                        <p>Emulation mode: <b className="text-primary">{profile?.emulationMode == '1' ? 'Gamepad' : 'Keyboard/Mouse' }</b></p>
                        <p>Connection mode: <b className="text-primary">{profile?.coonnectionType == '1' ? 'Bluetooth' : 'USB' }</b></p>

                        <p>Tags : </p>
                        {game?.tags?.map((tag: any) => <Chip style={{ fontSize: '12px', marginBottom: '4px', marginRight: '4px' }} label={tag} color="primary" ></Chip>)}

                    </Card>

                </div>
                <div>
                    <h3 className="text-primary mb-0">Description</h3>
                    <p className="mt-2">{profile?.detailedDescription}</p>
                </div>

                <div>
                    <h3 className="text-primary mb-0">Reviews</h3>
                    <Fieldset className="mt-4" legend={legendTemplate}>
                        <p className="m-0">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.
                        </p>
                    </Fieldset>
                </div>



            </div>

        </div>


    );
}

export default ProfileDetail;

const styles = {
    img: {
        width: '100%'
    },

    profileDetailCard: {
        padding: '0px'
    }
}

