import { platform } from "os";
import { GamePlatformTypeEnum } from "../../models/enums/GamePlatformTypeEnum";
import styles from "./SupportedPlatforms.module.css"


const PlatformImage = (props: any) =>{
    return <img {...props} style={{width: '20px', objectFit: 'cover', height: '20px'}}/>
}

const SupportedPlatforms = (props: any) => {
    const platforms = props.platforms as GamePlatformTypeEnum[]
    const list = platforms.map( platform =>{
        switch(platform.toLowerCase()){
            case GamePlatformTypeEnum.PlayStation: return <PlatformImage src="images/ps5.png" key="ps5"/>;
            case GamePlatformTypeEnum.XBox: return <PlatformImage src="images/xbox.png" key="xbox"/>;
            case GamePlatformTypeEnum.Android: return <PlatformImage src="images/android.png" key="android"/>;
            case GamePlatformTypeEnum.iOS: return <PlatformImage src="images/ios.png" key="ios"/>;
            case GamePlatformTypeEnum.PC: return <PlatformImage src="images/desktop.png" key="dedsktop"/>;
        }
    })

    return (
        <div className={styles.platforms}>
            <div className="flex flex-row gap-2">
                {list}
            </div>
        </div>
     );
}
 
export default SupportedPlatforms;