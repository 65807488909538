import { Tag } from "primereact/tag";
import styles from "./Tags.module.css"
import { Chip } from "primereact/chip";

const Tags = (props:any) => {
    let tags = props.tags as string[];

    tags = tags.slice(0, 4)
    tags = tags.map( tag => {
        if ( tag.length > 6 ){
            return tag.substring(0, 4) + "..."
        }
        return tag;
    })

    const list = tags.map( tag => <Chip style={{ fontSize: '0.8rem'}} label={tag} color="primary" key={tag}/> )
    return (
        <div className="flex flex-row gap-2">
            {list}
        </div>
    );
}
 
export default Tags;